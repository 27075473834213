import HP, { getServerSideProps as GetHPSSP } from './[lang]/index';

declare global {
  interface Window {
    maps: any;
    mapsLoaded?: boolean;
    initMaps?: (value: unknown) => void;
    analytics: any;
    ga: any;
  }
}

export default HP;
export const getServerSideProps = GetHPSSP;
